import {httpReq} from "../../../htttpReq";
import store from "../../store";

export default {
  namespaced: true,

  state: {
    userInfo: {
      user: {}
    },

    passwordInfo: {
      current_password: '',
      password: '',
      password_confirm: ''
    },

    prefer_entries: '',
    size_entering_type: '',
    skip_packages: '',
    is_show_sizes_on_estimates: '',
    notifications_redirect: '',
  },

  getters: {
    userInfo(state) {
      return state.userInfo;
    },

    passwordInfo(state){
      return state.passwordInfo;
    },

    preferEntries(state){
      return state.prefer_entries;
    },
    sizeEnteringType(state){
      return state.size_entering_type;
    },
    showSizes(state) {
      return state.is_show_sizes_on_estimates
    },
    skipPackages(state){
      return state.skip_packages
    },
    notificationsRedirect(state){
      return state.notifications_redirect
    }

  },

  mutations: {
    userInfo(state, payload) {
      state.userInfo = payload;
    },

    clearInput(state){
      for(let key in state.passwordInfo){
      }
    },

    updatePrefer(state, payload){
      localStorage.setItem('prefer_entries', payload)
      state.prefer_entries = payload
    },
    updateNotificationsRedirect(state, payload){
      localStorage.setItem('notifications_redirect', payload)
      state.notifications_redirect = payload
    },
    updateSizeEntering(state, payload){
      localStorage.setItem('size_entering_type', payload)
      state.size_entering_type = payload
    },
    showSizes(state, payload){
      localStorage.setItem('is_show_sizes_on_estimates', payload)
      state.is_show_sizes_on_estimates = payload
    },
    skipPackages(state, payload) {
      localStorage.setItem('skip_packages', payload)
      state.skip_packages = payload
    }
  },

  actions: {
    updateInfo({commit}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.post('user/update-account', {
          name: credentials.name,
          last_name: credentials.last_name,
          prefer_entry: credentials.prefer_entry,
          size_entering_type: credentials.size_entering_type,
          skip_packages: credentials.skip_packages,
          is_show_sizes_on_estimates: credentials.is_show_sizes_on_estimates,
          notifications_redirect: credentials.notifications_redirect,
        })
        .then(response => {
          commit('updatePrefer', response.data.profile.prefer_entry);
          commit('updateSizeEntering', response.data.profile.size_entering_type);
          commit('showSizes', response.data.profile.is_show_sizes_on_estimates);
          commit('updateNotificationsRedirect', response.data.profile.is_show_sizes_on_estimates);

          store.commit('orderPreview/updateFirstDimension', response.data.profile.prefer_entry);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    getInfo({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('user/update-account')
        .then(response => {
          commit('updatePrefer', response.data.prefer_entries);
          commit('updateSizeEntering', response.data.size_entering_type);
          commit('skipPackages', response.data.skip_packages ?? false);
          commit('showSizes', response.data.is_show_sizes_on_estimates);
          commit('updateNotificationsRedirect', response.data?.user?.notifications_redirect);
          commit('userInfo', response.data);
        })
        .catch((error) => {
          if(error.response.status == '401'){
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    //on page user update password
    changePassword({commit}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.post('user/update-account-password', {
          current_password: credentials.current_password,
          password: credentials.password,
          password_confirm: credentials.password_confirm,
        })
        .then(response => {
            localStorage.setItem('user-token', response.data.token);
            window.location.reload()
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
          store.commit('errorsModal/labelText', true);
        })
        .finally(() => {
          commit('clearInput');
          store.commit('preloader/preloader', false);
        })
    },
  },
};
